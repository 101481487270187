/* eslint-disable no-underscore-dangle */
import type { StrapiResponse } from '@betterpool/strapi-client';
import { parseStrapiResponse } from '@betterpool/strapi-client';
import { validate as validateUuid } from 'uuid';

import { fetchStrapiJSON } from '../../fetchStrapiJSON';
import { StrapiCollection } from '../../enums/strapi.enum';

type GetFeaturedContestsResponse = StrapiResponse<{
  contestIds: string[];
  location?: string[];
}>;

const parseResponse = (response: GetFeaturedContestsResponse, country?: string) => {
  const parsedStrapiResponse = parseStrapiResponse(response);
  const data = country
    ? parsedStrapiResponse.find((d) => {
        if (!d.__attributes.location?.length) {
          return true;
        }

        return d.__attributes.location.map((c) => c.toLowerCase()).includes(country.toLowerCase());
      })
    : parsedStrapiResponse[0];

  if (!data) return [];

  const contestIds = data.__attributes.contestIds ?? [];
  return contestIds.filter((contestId) => validateUuid(contestId));
};

const getFeaturedContests = (country?: string) =>
  fetchStrapiJSON<GetFeaturedContestsResponse>(
    StrapiCollection.HOME_PROMO_FEATURED_CONTESTS,
    {}
  ).then((data) => parseResponse(data, country));

export default getFeaturedContests;
