import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { ContestType } from '@betterpool/api-types/contests-service';

import type { MyUserContest } from '../types/userContest';

function useGetActionBannerInfo() {
  const { t } = useTranslation('contest');

  const getActionBannerInfo = useCallback(
    (contest: MyUserContest) => {
      if (typeof contest.isPicksheetAvailable === 'boolean' && !contest.isPicksheetAvailable) {
        let content = t('myContests.cards.banner.picksheetNotAvailable.teamBased');
        if (
          [ContestType.PLAYER_TIER, ContestType.PLAYER_ONE_AND_DONE].includes(contest.contest_type)
        ) {
          content = t('myContests.cards.banner.picksheetNotAvailable.playerBased');
        }

        return {
          color: 'gray',
          content,
        };
      }
      const missingPicksLength = contest.missingPicksEntryIds?.length;
      if (missingPicksLength) {
        if (contest.contest_type === ContestType.PLAYER_TIER)
          return t('myContests.cards.banner.draftLineup__hasPlural', { count: missingPicksLength });
        return t('myContests.cards.banner.makePicks');
      }
      const injuredPicksLength = contest.injuredPicksEntryIds?.length;
      if (injuredPicksLength) {
        return t('myContests.cards.banner.lineupWithInjuries__hasPlural', {
          count: injuredPicksLength,
        });
      }
      return undefined;
    },
    [t]
  );

  return { getActionBannerInfo };
}

export default useGetActionBannerInfo;
