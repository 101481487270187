import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';

import { logError } from '~/common/utils/log';
import { reportEvent } from '~/domains/analytics';
import type { getPopularChannels } from '~/services/commissioner/commissioner-service.service.api';
import {
  followChannel,
  unfollowChannel,
} from '~/services/commissioner/commissioner-service.service.api';
import { UserContext } from '~/components/providers/UserProvider';

import { ChannelsQueryKeys } from '../../query';

export const useFollowChannel = ({
  channelId,
  handle,
  location,
}: {
  channelId: string;
  handle: string;
  /** tracking */
  location: 'home' | 'channel_detail' | 'channel_preview';
}) => {
  const { t } = useTranslation('channel');

  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();

  const { mutate: handleFollow, isLoading: isFollowLoading } = useMutation({
    mutationFn: async () => {
      await followChannel(channelId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['commissioner/channel', handle] });

      queryClient.setQueryData(
        ChannelsQueryKeys.POPULAR_CHANNELS(user?.id),
        (oldData: Awaited<ReturnType<typeof getPopularChannels>>) => ({
          id: oldData?.id,
          data: oldData?.data.map((channel) => {
            if (channel.id === channelId) {
              return {
                ...channel,
                followersCount: channel.followersCount + 1,
                isFollowed: true,
              };
            }

            return channel;
          }),
        })
      );

      reportEvent('Channels > Follow Channel', {
        handle,
        location,
      });

      showNotification({
        title: t('notifications.followSuccess.title'),
        message: t('notifications.followSuccess.message', {
          handle,
        }),
        color: 'green',
        autoClose: 4000,
      });
    },
    onError: (error: AxiosError) => {
      showNotification({
        title: t('notifications.followError.title', { handle }),
        // @ts-expect-error message exists on data object
        message: error.response?.data?.message,
        color: 'red',
        autoClose: 4000,
      });
      logError(error);
    },
  });

  const { mutate: handleUnfollow, isLoading: isUnfollowLoading } = useMutation({
    mutationFn: async () => {
      await unfollowChannel(channelId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['commissioner/channel', handle] });

      queryClient.setQueryData(
        ChannelsQueryKeys.POPULAR_CHANNELS(user?.id),
        (oldData: Awaited<ReturnType<typeof getPopularChannels>>) => ({
          id: oldData?.id,
          data: oldData?.data.map((channel) => {
            if (channel.id === channelId) {
              return {
                ...channel,
                followersCount: channel.followersCount - 1,
                isFollowed: false,
              };
            }

            return channel;
          }),
        })
      );

      reportEvent('Channels > Unfollow Channel', {
        handle,
      });

      showNotification({
        title: t('notifications.unfollowSuccess.title'),
        message: t('notifications.unfollowSuccess.message', {
          handle,
        }),
        color: 'green',
        autoClose: 4000,
      });
    },
    onError: (error: AxiosError) => {
      showNotification({
        title: t('notifications.unfollowError.title', { handle }),
        // @ts-expect-error message exists on data object
        message: error.response?.data?.message,
        color: 'red',
        autoClose: 4000,
      });
      logError(error);
    },
  });

  return {
    handleUnfollow,
    handleFollow,
    isFollowLoading,
    isUnfollowLoading,
  };
};
