import { useContext } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { UserContext } from '~/components/providers/UserProvider';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

export const useHome = () => {
  const { user } = useContext(UserContext);

  const isCMSCarouselEnabled = useIsFeatureEnabled(FEATURE.ENABLE_CMS_CAROUSEL);

  return {
    isCMSCarouselEnabled,
    isSignedIn: !!user,
  };
};
