import React from 'react';
import { Card, Group, Text } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';

type EntryItemProps = {
  href: string;
  label: string;
  icon: JSX.Element;
  count: number;
};

function EntryItem({ href, label, icon, count }: EntryItemProps) {
  const { t } = useTranslation('home');

  return (
    <UnstyledNextLink href={href}>
      <Card
        px="md"
        shadow="sm"
        sx={{
          transition: 'transform ease-in-out 200ms',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Group align="center" spacing="xs">
          {icon}
          <Text fz="sm" fw="bold" sx={{ flexGrow: 1 }}>
            {label}
          </Text>
          {typeof count === 'number' ? (
            <Text fz="xs" color="gray.6">
              {t('myEntriesLinks.entriesCount__hasPlural', { count })}
            </Text>
          ) : null}
        </Group>
      </Card>
    </UnstyledNextLink>
  );
}

export default EntryItem;
