import { MantineProvider } from '@mantine/core';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { FEATURE } from '~/common/enums/feature.enum';
import { useHomePageEnabled } from '~/common/hooks/useHomePageEnabled';
import { useTreatments } from '~/domains/common/hooks/useTreatments';
import { Routes } from '~/domains/common/constants/routes';
import Home from '~/domains/home/Home';

function HomePage() {
  const router = useRouter();
  const { isReady: isLightThemeReady, treatments } = useTreatments([
    FEATURE.ENABLE_HOME_PAGE_LIGHT_THEME,
  ]);
  const isLightThemeEnabled = treatments[FEATURE.ENABLE_HOME_PAGE_LIGHT_THEME]?.treatment === 'on';

  const { isReady: isHomePageReady, isHomePageEnabled } = useHomePageEnabled();

  const isReady = isLightThemeReady && isHomePageReady;

  useEffect(() => {
    if (isReady && !isHomePageEnabled) {
      router.replace(Routes.contestLobby());
    }
  }, [isHomePageEnabled, isReady, router]);

  if (!isReady || !isHomePageEnabled) {
    return null;
  }

  return (
    <MantineProvider inherit theme={{ colorScheme: isLightThemeEnabled ? 'light' : 'dark' }}>
      <Home />
    </MantineProvider>
  );
}

export default HomePage;

HomePage.isPublic = true;
