import { Flex, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { LinkNew } from '~/domains/common/components/Link/Link';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { Title } from '~/domains/design-system/Title';
import { Text } from '~/domains/design-system/Text';

export function HomeSectionTitle({ children }: { children: React.ReactNode }) {
  const theme = useMantineTheme();
  const isMobile = useIsMobile();

  return (
    <Title
      variant={isMobile ? 'headline-large' : 'headline-xl'}
      my={theme.spacing.lg}
      tag="h3"
      color="black"
    >
      {children}
    </Title>
  );
}

export function HomeViewAllLink({ href }: { href: string }) {
  const { t } = useTranslation('home');

  return (
    <Flex align="center">
      <LinkNew href={href}>
        <Text variant="body-large">{t('common.viewAll')}</Text>
      </LinkNew>
    </Flex>
  );
}
