import React from 'react';
import { IconCalendarDue, IconCircleDot, IconClockHour3 } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';

import { Routes } from '~/domains/common/constants/routes';
import { ContestStatusFilter } from '~/domains/contest/domains/services/enums/contest-status-filter.enum';
import { useMyContestsPageEnabled } from '~/domains/common/hooks/useMyContestsPageEnabled';

import { HomeSectionTitle } from '../sectionComponents';

import EntryItem from './EntryItem';
import EntriesSectionContainer from './EntriesSectionContainer';

type EntrySectionProps = {
  entriesCount?: {
    [ContestStatusFilter.SCHEDULED]?: number;
    [ContestStatusFilter.IN_PROGRESS]?: number;
    [ContestStatusFilter.FINALIZED]?: number;
  };
};

function MyEntriesLinks({ entriesCount = {} }: EntrySectionProps) {
  const { isMyContestsPageEnabled } = useMyContestsPageEnabled();
  const { t } = useTranslation('home');

  return (
    <div>
      <HomeSectionTitle>{t('myEntriesLinks.title')}</HomeSectionTitle>
      <EntriesSectionContainer>
        <EntryItem
          href={
            isMyContestsPageEnabled
              ? Routes.myContests({ contestStatus: ContestStatusFilter.SCHEDULED })
              : Routes.myEntries({ entryStatus: ContestStatusFilter.SCHEDULED })
          }
          icon={<IconClockHour3 />}
          label={t('myEntriesLinks.upcoming')}
          count={entriesCount[ContestStatusFilter.SCHEDULED]}
        />

        <EntryItem
          href={
            isMyContestsPageEnabled
              ? Routes.myContests({ contestStatus: ContestStatusFilter.IN_PROGRESS })
              : Routes.myEntries({ entryStatus: ContestStatusFilter.IN_PROGRESS })
          }
          icon={<IconCircleDot />}
          label={t('myEntriesLinks.live')}
          count={entriesCount[ContestStatusFilter.IN_PROGRESS]}
        />
        <EntryItem
          href={
            isMyContestsPageEnabled
              ? Routes.myContests({ contestStatus: ContestStatusFilter.FINALIZED })
              : Routes.myEntries({ entryStatus: ContestStatusFilter.FINALIZED })
          }
          icon={<IconCalendarDue />}
          label={t('myEntriesLinks.finalized')}
          count={entriesCount[ContestStatusFilter.FINALIZED]}
        />
      </EntriesSectionContainer>
    </div>
  );
}

export default MyEntriesLinks;
