import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';
import { useRouter } from 'next/router';

import type { Channel } from '~/domains/commissioner/interfaces/channel.interface';
import { Routes } from '~/domains/common/constants/routes';
import { UserContext } from '~/components/providers/UserProvider';

import { formatChannelDetails } from '../../utils/formatChannelDetails';
import { useFollowChannel } from '../ChannelHeader/useFollowChannel';

import { PopularChannel } from './PopularChannel';

export function ConnectedPopularChannel({ channel }: { channel: Channel }) {
  const { user } = useContext(UserContext);
  const router = useRouter();
  const { t } = useTranslation('channel');

  const { handleFollow, isFollowLoading, handleUnfollow, isUnfollowLoading } = useFollowChannel({
    channelId: channel.id,
    handle: channel.handle,
    location: 'home',
  });

  return (
    <PopularChannel href={Routes.channel(channel.handle)}>
      <PopularChannel.Banner src={channel.backdropUrl} />

      <PopularChannel.Avatar src={channel.avatarUrl} handle={channel.handle} />

      <PopularChannel.Content>
        <PopularChannel.Info>
          <PopularChannel.Name>{channel.name}</PopularChannel.Name>
          <PopularChannel.Handle>{channel.handle}</PopularChannel.Handle>
        </PopularChannel.Info>

        <PopularChannel.Numbers>
          <PopularChannel.NumbersItem>
            <PopularChannel.Number>
              {formatChannelDetails(channel.contestsCount)}
            </PopularChannel.Number>
            <PopularChannel.NumberLabel>{t('header.numbers.contests')}</PopularChannel.NumberLabel>
          </PopularChannel.NumbersItem>
          <PopularChannel.NumbersItem>
            <PopularChannel.Number>
              {formatChannelDetails(channel.entriesCount)}
            </PopularChannel.Number>
            <PopularChannel.NumberLabel>{t('header.numbers.entries')}</PopularChannel.NumberLabel>
          </PopularChannel.NumbersItem>

          <PopularChannel.NumbersItem>
            <PopularChannel.Number>
              {formatChannelDetails(channel.followersCount)}
            </PopularChannel.Number>
            <PopularChannel.NumberLabel>{t('header.numbers.followers')}</PopularChannel.NumberLabel>
          </PopularChannel.NumbersItem>
        </PopularChannel.Numbers>
      </PopularChannel.Content>

      <PopularChannel.Divider />

      <PopularChannel.Actions>
        <PopularChannel.ActionsButton
          isFollowed={channel.isFollowed}
          disabled={isFollowLoading || isUnfollowLoading}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            if (!user) {
              router.push(Routes.signIn({ redirectTo: Routes.channel(channel.handle) }));
              return;
            }

            if (channel.isFollowed) {
              handleUnfollow();
            } else {
              handleFollow();
            }
          }}
        >
          {channel.isFollowed ? t('header.followingButton') : t('header.followButton')}
        </PopularChannel.ActionsButton>
      </PopularChannel.Actions>
    </PopularChannel>
  );
}
