import React from 'react';
import { Group, Stack } from '@mantine/core';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

type EntriesSectionContainerProps = {
  children: React.ReactNode;
};

function EntriesSectionContainer({ children }: EntriesSectionContainerProps) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Stack spacing="sm">{children}</Stack>
  ) : (
    <Group position="apart" grow spacing="xl">
      {children}
    </Group>
  );
}

export default EntriesSectionContainer;
