import { Carousel } from '@mantine/carousel';
import { createStyles } from '@mantine/core';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React from 'react';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

type HomeCarouselProps = {
  children: ReactNode;
  /**
   * Width of the slide, can be any css size value.
   * Note: currently it overrides any value in breakpoints, which is not exposed via props */
  slideSize?: ComponentPropsWithoutRef<typeof Carousel>['slideSize'];
};

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 12,
    height: 8,
    background: theme.colors.gray[3],
    '&[data-active]': {
      width: 24,
    },
    transition: 'width 250ms ease',
  },
  indicators: {
    bottom: -20,
  },
  slide: {
    width: '100%',
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '50%',
    },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      maxWidth: 'calc(100% / 3)',
    },
  },
  controls: {
    position: 'absolute',
    right: 72,
    top: -46,
    height: 26,
  },
  control: {
    position: 'absolute',
    '&:first-of-type': {
      right: 35,
    },
    '&:last-child': {
      right: 0,
      top: 0,
    },
  },
}));

function HomeCarousel({ children, slideSize }: HomeCarouselProps) {
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  const numberOfContestInCarousel = React.Children.toArray(children).length;

  return (
    <Carousel
      slideSize={slideSize ?? `calc(100% / 3)`}
      slideGap="xl"
      draggable
      withControls={!isMobile && numberOfContestInCarousel > 1}
      withIndicators={isMobile && numberOfContestInCarousel > 1}
      align="start"
      classNames={{
        indicator: classes.indicator,
        indicators: classes.indicators,
        slide: classes.slide,
        control: classes.control,
        controls: classes.controls,
      }}
      breakpoints={[
        { maxWidth: 'md', slideSize: slideSize ?? '50%', slideGap: 20 },
        { maxWidth: 'sm', slideSize: slideSize ?? '100%', slideGap: 20 },
      ]}
      containScroll="trimSnaps"
    >
      {children}
    </Carousel>
  );
}

HomeCarousel.Slide = Carousel.Slide;

export default HomeCarousel;
