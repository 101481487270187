import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { STALE_TIME } from '~/domains/contest/constants/query';
import { getPopularChannels } from '~/services/commissioner/commissioner-service.service.api';
import { isLoadingFixedForDisabledQuery } from '~/domains/common/utils/isLoadingFixedForEnabled';
import { UserContext } from '~/components/providers/UserProvider';

import { ChannelsQueryKeys } from '../../query';

export const usePopularChannels = ({ enabled = true }) => {
  const { user } = useContext(UserContext);

  const { data, fetchStatus, isLoading, isError } = useQuery({
    queryKey: ChannelsQueryKeys.POPULAR_CHANNELS(user?.id),
    queryFn: getPopularChannels,
    staleTime: STALE_TIME,
    enabled,
  });

  return {
    data,
    isLoading: isLoadingFixedForDisabledQuery(isLoading, fetchStatus),
    isError,
  };
};
