import { createStyles } from '@mantine/core';

const useHomeStyles = createStyles((theme) => ({
  carousel: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    gap: '40px',
    padding: theme.other.layout.paddingLg,

    [theme.fn.smallerThan('lg')]: {
      padding: theme.other.layout.paddingMd,
    },
    [theme.fn.smallerThan('md')]: {
      padding: theme.other.layout.paddingSm,
    },
  },
}));

export default useHomeStyles;
