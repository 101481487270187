import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatment } from '~/domains/common/hooks/useTreatment';

import HomeSkeletonLayout from './HomeSkeleton';
import MyEntries from './MyEntries';
import MyEntriesLinks from './MyEntriesLinks/MyEntriesLinks';

function HomeMyEntriesSection() {
  const { isReady, treatment } = useTreatment(FEATURE.ENABLE_ENTRIES_PAGE);
  const isEnabled = treatment?.treatment === 'on';

  if (!isReady) {
    return <HomeSkeletonLayout isLoading />;
  }

  if (!isEnabled) {
    return <MyEntriesLinks />;
  }

  return <MyEntries />;
}

export default HomeMyEntriesSection;
