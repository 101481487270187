import { CarouselWithCards } from '~/components/shared/CarouselWithCards/CarouselWithCards';
import { CarouselSpot } from '~/domains/cms/promo/carousel.contests/enums';
import { ConnectedCarousel } from '~/domains/contest/domains/lobby/components/Carousel/ConnectedCarousel';

type HomeHeaderProps = {
  isCMSCarouselEnabled: boolean;
};

function HomeHeader({ isCMSCarouselEnabled }: HomeHeaderProps) {
  return isCMSCarouselEnabled ? (
    <ConnectedCarousel carouselSpot={CarouselSpot.HOMEHEADER} />
  ) : (
    <CarouselWithCards />
  );
}

export default HomeHeader;
