import { useTheme } from '@emotion/react';
import { Flex, Skeleton } from '@mantine/core';

type HomeSkeletonLayoutProps = {
  isLoading: boolean;
};

function HomeSkeletonLayout({ isLoading }: HomeSkeletonLayoutProps) {
  const theme = useTheme();

  return (
    <Flex direction="column" gap={theme.spacing.sm}>
      <Skeleton h={40} visible={isLoading} />
      <Skeleton mih={170} visible={isLoading} />
    </Flex>
  );
}

export default HomeSkeletonLayout;
