import { DEFAULT_STRAPI_STALE_TIME } from '@betterpool/strapi-client';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { LocationContext } from '~/components/providers/LocationProvider';

import getPromoContests from './getPromoContests';

export const usePromoContests = () => {
  const { locationCountryNameSafe, isLoading } = useContext(LocationContext);

  return useQuery({
    queryKey: ['cms@promoContests', locationCountryNameSafe],
    queryFn: () => getPromoContests(locationCountryNameSafe),
    staleTime: DEFAULT_STRAPI_STALE_TIME,
    enabled: !!locationCountryNameSafe && !isLoading,
  });
};
