import type { FEATURE } from '~/common/enums/feature.enum';

import { useTreatments } from './useTreatments';

type TreatmentWithConfig = {
  treatment: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTreatment = <F extends FEATURE, CF = any>(
  featureFlagName: F,
  attributes?: SplitIO.Attributes,
  key?: SplitIO.SplitKey
): {
  isReady: boolean;
  treatment: TreatmentWithConfig | null;
} => {
  const { isReady, treatments } = useTreatments<F, Record<F, CF>>(
    [featureFlagName],
    attributes,
    key
  );

  return {
    isReady,
    treatment: treatments[featureFlagName] || null,
  };
};
