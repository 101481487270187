import type { CardProps } from '@mantine/core';
import { Box, Card, Divider, Group, Stack } from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';
import type { ComponentPropsWithoutRef } from 'react';

import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';
import { Avatar } from '~/domains/design-system/Avatar';
import { UnstyledButton } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';

// Note: This component is very similar to the ChannelHeader - there might be some shared components that could be extracted
const PADDING = 16;

export function PopularChannel({ href, ...props }: CardProps & { href: string }) {
  return <Card component={Link} href={href} p={0} radius={8} {...props} />;
}

function PopularChannelBanner({
  src = getCdnUrl('/images/channels/channel-banner-default.png'),
  children,
}: {
  src?: string;
  children?: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '80px',
        img: { objectFit: 'cover' },
      }}
    >
      <Image src={src} fill role="banner" alt="" />
      {children}
    </Box>
  );
}

const AVATAR_SIZE = 56;
function PopularChannelAvatar(props: ComponentPropsWithoutRef<typeof Avatar>) {
  return (
    <Stack align="center" mt={AVATAR_SIZE / -2} pos="relative" sx={{ zIndex: 1 }}>
      <Avatar
        size={AVATAR_SIZE}
        sx={{
          border: '2px solid #fff',
        }}
        {...props}
      />
    </Stack>
  );
}

function PopularChannelContent({ children, ...props }: ComponentPropsWithoutRef<typeof Stack>) {
  return (
    <Stack py={4} px={PADDING} spacing={8} align="center" {...props}>
      {children}
    </Stack>
  );
}

function PopularChannelInfo({ children }: { children: React.ReactNode }) {
  return (
    <Stack spacing={0} pb={0} align="center">
      {children}
    </Stack>
  );
}

function PopularChannelName({
  children,
  endAdornment,
}: {
  children: React.ReactNode;
  endAdornment?: React.ReactNode;
}) {
  return (
    <Group spacing={4}>
      <Text
        variant="headline-large"
        color="gray.9"
        sx={{
          maxWidth: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Text>
      {endAdornment}
    </Group>
  );
}

function PopularChannelHandle({ children }: { children: React.ReactNode }) {
  return (
    <Text variant="body-medium" color="gray.6">
      @{children}
    </Text>
  );
}

function PopularChannelNumbers({ children }: { children: React.ReactNode }) {
  return (
    <Group
      spacing={0}
      noWrap
      sx={{
        a: { padding: 0 },
      }}
    >
      {children}
    </Group>
  );
}

function PopularChannelNumbersItem({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      spacing={0}
      align="center"
      sx={{ flex: '1 1 0', minWidth: 0, overflow: 'hidden' }}
      px={10}
    >
      {children}
    </Stack>
  );
}

function PopularChannelNumber({ children }: { children: React.ReactNode }) {
  return (
    <Text variant="headline-medium" color="gray.9">
      {children}
    </Text>
  );
}
function PopularChannelNumberLabel({ children }: { children: React.ReactNode }) {
  return (
    <Text variant="body-medium" color="gray.6">
      {children}
    </Text>
  );
}

function PopularChannelDivider() {
  return (
    <Divider
      color="gray.1"
      sx={{ alignSelf: 'center' }}
      // TODO: once the typography is set, the height most likely won't be needed
    />
  );
}

function PopularChannelActions({ children }: { children: React.ReactNode }) {
  return (
    <Group py={8} px={PADDING}>
      {children}
    </Group>
  );
}

function PopularChannelActionsButton({
  isFollowed,
  ...props
}: ComponentPropsWithoutRef<typeof UnstyledButton> & { isFollowed?: boolean }) {
  return (
    <Text
      variant="subhead-small"
      color={isFollowed ? 'gray.9' : 'blue.7'}
      sx={{
        flex: 1,
        textAlign: 'center',
        button: {
          width: '100%',
          '&:hover': {
            textDecoration: 'underline',
          },
          '&:active': {
            position: 'relative',
            top: '1px',
          },
        },
      }}
    >
      <UnstyledButton {...props} />
    </Text>
  );
}

PopularChannel.Banner = PopularChannelBanner;

PopularChannel.Content = PopularChannelContent;

PopularChannel.Info = PopularChannelInfo;
PopularChannel.Avatar = PopularChannelAvatar;
PopularChannel.Name = PopularChannelName;
PopularChannel.Handle = PopularChannelHandle;

PopularChannel.Numbers = PopularChannelNumbers;
PopularChannel.NumbersItem = PopularChannelNumbersItem;
PopularChannel.Number = PopularChannelNumber;
PopularChannel.NumberLabel = PopularChannelNumberLabel;

PopularChannel.Divider = PopularChannelDivider;

PopularChannel.Actions = PopularChannelActions;
PopularChannel.ActionsButton = PopularChannelActionsButton;
