import { useMemo } from 'react';
import { ContestStatus } from '@betterpool/api-types/contests-service';
import type { ContestType } from '@betterpool/api-types/contests-service';
import { useRouter } from 'next/router';

import useContestsUser from '~/domains/contest/hooks/useContestsUser';
import { URLQueryKeys } from '~/domains/common/types/URLQueryKeys';
import { useIsNotebook } from '~/domains/common/hooks/mediaQueries';

import { ContestStatusFilter } from '../../services/enums/contest-status-filter.enum';
import parseMyContests from '../utils/parseMyContests';
import type { TSortByDir, TSortByOption } from '../../services/contest.service.api';

const useMyContests = ({
  status,
  limit,
}: {
  status?: ContestStatusFilter;
  limit?: number;
} = {}) => {
  const router = useRouter();
  const currentQuery = { ...router.query };

  const URLContestStatusFilter =
    status ??
    ((currentQuery[URLQueryKeys.CONTEST_STATUS] ||
      ContestStatusFilter.ACTIVE) as ContestStatusFilter);
  const URLContestTypeFilter = currentQuery[URLQueryKeys.CONTEST_TYPE] as ContestType;
  const URLSortBy = currentQuery[URLQueryKeys.SORT_BY] as TSortByOption;
  const URLSortByOrder = currentQuery[URLQueryKeys.SORT_ORDER] as TSortByDir;
  const isNotebook = useIsNotebook();
  const dataLimit = limit ?? (isNotebook ? 20 : 21);

  const data = useContestsUser({
    status: URLContestStatusFilter,
    contestType: URLContestTypeFilter,
    limit: dataLimit,
    sort: URLSortBy
      ? {
          option: URLSortBy,
          dir: URLSortByOrder,
        }
      : undefined,
  });

  const flatUserContests = useMemo(() => {
    const contests = data.data?.pages
      ?.map((page) => page.data)
      .flat()
      .map((contest) => ({
        ...contest,
        contest: {
          ...contest.contest,
          status: !contest.contest.isAfterEntryDeadline
            ? ContestStatus.SCHEDULED
            : contest.contest.status,
        },
      }));
    if (contests?.length) {
      return contests;
    }
    return [];
  }, [data.data?.pages]);

  const parsedContests = parseMyContests(flatUserContests);

  return {
    contests: parsedContests,
    isLoading: data.isLoading,
    isError: data.isError,
    fetchNextPage: data.fetchNextPage,
    hasNextPage: data.hasNextPage,
    itemsTotal: data.itemsTotal,
    contestStatusFilter: URLContestStatusFilter,
  };
};

export default useMyContests;
